import { generateIdFromArn, getIdInfoFromArn, getScopeForArn, Scope } from 'src/components/utils/arnUtil';
import { isValidWorkspace } from 'src/commons/validationUtils';

export const Page = {
  /*
      NOTE: the order of the paths should be noted here, as they are checked
      in this order. For example, connections/new comes before connections/:id
      so that 'new' is not taken as the ID of a connection and the wrong page
      rendered. Breadcrumbs.tsx, breadcrumbx.tsx, and Routes.tsx should follow
      this order too.
    */

  HOME: '/',
  ONBOARDING: '/onboarding',
  CREATE_NEW_GROUP: '/groups/new',

  BROWSE_CATALOGS: '/catalogs',

  BROWSE_DATABASES: '/databases',

  BROWSE_DATASETS: '/datasets',

  METADATA_FORMS: '/metadataForms',
  VIEW_METADATA_FORM: '/metadataForms/:id/:formOwnerId',
  CREATE_METADATA_FORM: '/metadataForms/create',
  EDIT_METADATA_FORM: '/metadataForms/edit',

  BUSINESS_GLOSSARIES: '/glossaries',
  VIEW_BUSINESS_GLOSSARY: '/glossaries/:id/:glossaryOwnerId',
  CREATE_BUSINESS_GLOSSARY: '/glossaries/create',
  EDIT_BUSINESS_GLOSSARY: '/glossaries/edit',

  CONNECTIONS: '/connections',
  CREATE_CONNECTION: '/connections/new',
  CONNECTION_DETAILS: '/connections/:id',

  SUBSCRIPTIONS: '/subscriptions',
  CREATE_SUBSCRIPTION: '/subscriptions/new',
  SUBSCRIPTION_DETAILS: '/subscriptions/:id',

  PUBLISH: '/publish',

  PUBLISHER_TABLES: '/publisher/tables',
  CREATE_PUBLISHER_TABLE: '/publisher/tables/new',
  PUBLISHER_CREATE_BACKFILL: '/publisher/tables/:TableId/:TableName/createBackfill',
  PUBLISHER_POST_DEDUPE: '/publisher/tables/:TableId/:TableName/dedupe',
  PUBLISHER_TABLE_DETAILS: '/publisher/tables/:TableId/:TableName',

  PUBLISHER_ACCOUNTS: '/publisher/accounts',
  PUBLISHER_REGISTER_ACCOUNT: '/publisher/accounts/new',

  MY_DATASETS: '/mydatasets',
  CREATE_LAKE_FORMATION_PERMISSIONS: '/mydatasets/lakeformation/new',
  DATASETSHARE_DETAILS: '/mydatasets/:id',

  REQUESTS: '/requests',
  REQUEST_DETAILS: '/requests/:id',

  DEPENDENCIES: '/dependencies',
  CREATE_DEPENDENCY: '/dependencies/new',
  DEPENDENCY_DETAILS: '/dependency/:id',

  EVENT_SUBSCRIPTIONS: '/eventsubscriptions',
  EVENT_SUBSCRIPTIONS_DETAILS: '/eventsubscriptions/:id',

  RESOURCEGROUPS: '/resourcegroups',
  CREATE_RESOURCEGROUP: '/resourcegroups/new',
  UPDATE_RESOURCEGROUP: '/resourcegroups/edit/:id',
  RESOURCEGROUP_DETAILS: '/resourcegroups/:id',
  RESOURCE_DETAILS: '/resourcegroups/:rgid/:id',

  BOOTSTRAPACTIONS: '/bootstrapactions',
  CREATE_BOOTSTRAPACTION: '/bootstrapactions/new',
  UPDATE_BOOTSTRAPACTION: '/bootstrapactions/edit/:id',
  BOOTSTRAPACTION_DETAILS: '/bootstrapactions/:id',

  CREATE_RG_TEMPLATE: '/template/new',
  UPDATE_RG_TEMPLATE: '/template/edit/:id',
  RG_TEMPLATE_DETAILS: '/template/:id',
  CHECKOUT: '/checkout',

  NOTIFICATIONS: '/notifications',
  CREATE_NOTIFICATION: '/notifications/new',
  NOTIFICATION_DETAILS: '/notifications/:NotificationId',
  NOTIFICATION_BATCH_DETAILS: '/notification-batch/:NotificationBatchId',

  NOTIFICATION_TEMPLATES: '/notificationtemplates',
  CREATE_NOTIFICATION_TEMPLATE: '/notificationtemplates/create',
  UPDATE_NOTIFICATION_TEMPLATE: '/notificationtemplates/:NotificationTemplateId/update',
  NOTIFICATION_TEMPLATE_DETAILS: '/notificationtemplates/:NotificationTemplateId',
  NOTIFICATION_CONFIGURATION: '/notification-configuration',

  GROUP: '/groups',
  GROUPS: '/groups/:id',

  WORKSPACEACCESS: '/workspaceaccess',

  TAGS: '/tags',
  CREATE_TAGS_TEMPLATE: '/tags/create',
  EDIT_TAGS_TEMPLATE: '/tags/edit',

  FINE_GRAIN_ACCESS_POLICIES: '/fineGrainAccessPolicies',
  VIEW_FINE_GRAIN_ACCESS_POLICY: '/fineGrainAccessPolicies/:id',
  CREATE_FINE_GRAIN_ACCESS_POLICY: '/fineGrainAccessPolicies/create',
  EDIT_FINE_GRAIN_ACCESS_POLICY: '/fineGrainAccessPolicies/edit',

  BASELINING: '/baselining',
  BASELINE_DATA_ACCESS_PERMISSION: '/baseline/data-access-permission/:ownerId',
  BASELINE_OWNER: '/baseline/owner/:ownerId',

  LIST_ADVISORIES: '/advisories',
  CREATE_ADVISORY: '/advisories/new',
  CREATE_ADVISORY_FROM_TEMPLATE: '/advisories/new/templateId/:templateid',
  CREATE_ADVISORY_FROM_ADVISORY: '/advisories/new/advisoryId/:id',
  UPDATE_ADVISORY: '/advisories/update/:id',
  ADVISORY_SEND_NOTIFICATION: '/advisories/notify/:id', // need error handling
  LIST_ADVISORY_TEMPLATES: '/advisories/templates',
  UPDATE_ADVISORY_TEMPLATE: '/advisories/templates/update/:id',
  ADVISORY_TEMPLATE_DETAIL: '/advisories/templates/:id',
  ADVISORY_DETAIL: '/advisories/:id',

  // INGESTION
  INGESTION: {
    REGISTER_CONTRACT: '/publisher/register',
    DETAIL: '/publisher/dataset/:arn/:dataset',
    DETAIL_V2: '/publisher/dataset/:arn/:database/:dataset',
    UPDATE_CONTRACT: '/publisher/dataset/update/:arn/:dataset',
    UPDATE_CONTRACT_V2: '/publisher/dataset/update/:arn/:database/:dataset',
    MONITORING: '/publisher/monitoring/:team/:database/:arn/:dataset',
    MONITORING_V2: '/publisher/monitoring/:arn/:database/:dataset',
  },

  // MetricStore
  METRICS_CATALOG: '/metrics-catalog',
  METRIC_DEFINITION: '/metrics-catalog/:id',
  METRIC_COLLECTIONS_CATALOG: '/metric-collections-catalog',
  METRIC_COLLECTIONS_DEFINITION: '/metric-collections-catalog/:id',
  CREATE_METRIC_COLLECTIONS_CATALOG: '',
  REPORTING_TABLE_CATALOG: '/reporting-table-catalog',
  REPORTING_TABLE_DEFINITION: '/reporting-table-catalog/:id',
  METRIC_COLLECTION_MONTHLY_VIEW: '/metric-collections-monthly/:id',
  METRIC_COLLECTION_QUARTERLY_VIEW: '/metric-collections-quarterly/:id',
  METRIC_COLLECTION_WEEKLY_VIEW: '/metric-collections-weekly/:id',
  METRIC_COLLECTION_YEARLY_VIEW: '/metric-collections-yearly/:id',

  // Deprecated paths should still exist for URL backwards compatibility
  // if you change a route you need to keep the old one and redirect to the new route from Routes.tsx
  DEPRECATED_PERMISSIONS: '/permissions',
  DEPRECATED_PERMISSIONS_DETAILS: '/permissions/:id',
  DEPRECATED_CATALOG: '/catalog',
  DEPRECATED_CATALOG_DETAILS: '/catalog/:id',

  CREATE_WORKSPACE: '/workspaces/new',
  BROWSE_WORKSPACES: '/current-workspace',
  WORKSPACE_MY_REQUEST_DETAILS: '/workspaces-requests/:id',
  WORKSPACE_MY_REQUESTS: '/workspaces-requests',
  UPDATE_WORKSPACE: '/workspaces/:workspaceId/update',
  CURRENT_WORKSPACE_DETAIL: '/current-workspace/:workspaceId',

  WORKSPACE_GLUE_REGISTER: '/workspaces-glue-register',
  WORKSPACE_REDSHIFT_REGISTER: '/workspaces-redshift-register',

  WORKSPACE_TAG_REGISTER: '/workspaces-tag-register',

  WORKSPACE_ALL_PERMISSIONS: '/workspaces-permission',

  DATABASE_DETAILS: '/databases/:catalogid/:databasename/:region',
  CATALOG_DETAILS: '/catalogs/:catalogid/:region',
  DATASET_DETAILS: '/datasets/:id',
  SCHEMA_DETAILS: '/databases/:catalogid/:clustername/:databasename/schemas/:schemaname/:region',
  REDSHIFT_CATALOG_DETAILS: '/catalogs/:catalogid/:clustername/:region',
  REDSHIFT_DATABASE_DETAILS: '/databases/:catalogid/:clustername/:databasename/:region',

  DEPRECATED_DATABASE_DETAIL: '/workspaces-databases/:catalogid/:databasename/:region',
  DEPRECATED_CATALOG_DETAIL: '/workspaces-catalogs/:catalogid/:region',
  DEPRECATED_DATASET_DETAIL: '/workspaces-datasets/:id',
  DEPRECATED_SCHEMA_DETAIL: '/workspaces-databases/:catalogid/:clustername/:databasename/schemas/:schemaname/:region',
  DEPRECATED_REDSHIFT_CATALOG_DETAILS: '/catalogs/:catalogid/:clustername/:region',
  DEPRECATED_REDSHIFT_DATABASE_DETAILS: '/databases/:catalogid/:clustername/:databasename/:region',

  WORKSPACES_BROWSE_DATASETS: '/workspaces-datasets',
  WORKSPACES_BROWSE_DATABASES: '/workspaces-databases',
  WORKSPACES_BROWSE_CATALOGS: '/workspaces-catalogs',
  WORKSPACE: '/workspace',
  CURRENT_WORKSPACE: '/current-workspace',
  PERMISSION_DETAIL: '/workspace-permissions/:permissionId',

  //
  TEMPLATES: '/templates',
  CREATE_TEMPLATE: '/templates/new',
  TEMPLATE_DETAILS: '/templates/:templateId/:resourceId',
  EDIT_TEMPLATE: '/templates/edit',

  LF_PERMISSION_DETAIL: '/workspaces-permission/lf/:permissionId',
  PUBLISHED_PERMISSION_DETAIL: '/workspaces-permission/published/:permissionId',

  REDSHIFT_PERMISSION_DETAIL: '/workspaces-permission/redshift/:permissionId',
  WORKSPACE_RESOURCE_LINKS: '/workspaces-resource-links',

  WORKSPACE_REDSHIFT_QUERY: '/workspace-redshift-query',

  SEARCH: '/search',

  WORKSPACE_DETAIL: '/search/workspaces/:workspaceId',

  TAG_DETAIL: '/search/tags/:ownerid/:tagid',

  DATAZONE_MANAGE: '/datazone',
  DATAZONE_ONBOARD: '/datazone/onboard',

  WORKFLOW_STATUS: '/workflow/:id',
};

export function createPublisherTableLink(tableId, tableName) {
  return Page.PUBLISHER_TABLES + '/' + tableId + '/' + tableName;
}

export function createResourceGroupLink(id) {
  return Page.RESOURCEGROUPS + '/' + id;
}

export function createBootstrapActionLink(id) {
  return Page.BOOTSTRAPACTIONS + '/' + id;
}

export function createTemplateLink(id) {
  return Page.RG_TEMPLATE_DETAILS.replace(':id', id);
}

export function createWorkspaceDetailLink(workspaceId) {
  return Page.WORKSPACE_DETAIL.replace(':workspaceId', workspaceId);
}

export function createEventSubscriptionDetailsLink(id) {
  return Page.EVENT_SUBSCRIPTIONS_DETAILS.replace(':id', id);
}

export function createTagDetailLink(ownerId, tagId) {
  return Page.TAG_DETAIL.replace(':ownerid', ownerId).replace(':tagid', tagId);
}

export function createGroupDetailLink(id) {
  return Page.GROUPS.replace(':id', id);
}

export function createBaselineOwnerLink(ownerId: string) {
  return Page.BASELINE_OWNER.replace(':ownerId', ownerId);
}

export function createOwnerLink(ownerId: string) {
  return isValidWorkspace(ownerId) ? createWorkspaceDetailLink(ownerId) : createGroupDetailLink(ownerId);
}

export function createGlueCatalogDetailLink(catalogId, region) {
  return Page.CATALOG_DETAILS.replace(':catalogid', catalogId).replace(':region', region);
}

export function createRedshiftCatalogDetailLink(catalogId, clusterName, region) {
  return Page.REDSHIFT_CATALOG_DETAILS.replace(':catalogid', catalogId)
    .replace(':clustername', clusterName)
    .replace(':region', region);
}

export function createGlueDatabaseDetailLink(catalogId, databaseName, region) {
  return Page.DATABASE_DETAILS.replace(':catalogid', catalogId)
    .replace(':databasename', databaseName)
    .replace(':region', region);
}

export function createRedshiftDatabaseDetailLink(catalogId, clusterName, databaseName, region) {
  return Page.REDSHIFT_DATABASE_DETAILS.replace(':catalogid', catalogId)
    .replace(':clustername', clusterName)
    .replace(':databasename', databaseName)
    .replace(':region', region);
}

export function createSchemaDetailLink(catalogId, clusterIdentifier, databaseName, schemaName, region) {
  return Page.SCHEMA_DETAILS.replace(':catalogid', catalogId)
    .replace(':clustername', clusterIdentifier)
    .replace(':databasename', databaseName)
    .replace(':schemaname', schemaName)
    .replace(':region', region);
}

export function createDatasetDetailLink(id) {
  return Page.DATASET_DETAILS.replace(':id', id);
}

export function createRedshiftPermissionDetailLink(permissionId) {
  return Page.REDSHIFT_PERMISSION_DETAIL.replace(':permissionId', permissionId);
}

export function createLFPermissionDetailLink(permissionId) {
  return Page.LF_PERMISSION_DETAIL.replace(':permissionId', permissionId);
}

export function createPublishedPermissionDetailLink(permissionId) {
  return Page.PUBLISHED_PERMISSION_DETAIL.replace(':permissionId', permissionId);
}

export function createFgaPolicyViewLink(policyId) {
  return Page.VIEW_FINE_GRAIN_ACCESS_POLICY.replace(':id', policyId);
}

export function createCurrentWSDetailLink(workspaceId) {
  return Page.CURRENT_WORKSPACE_DETAIL.replace(':workspaceId', workspaceId);
}

export function createTemplateDetailsLink(templateId, resourceId) {
  return Page.TEMPLATE_DETAILS.replace(':templateId', templateId).replace(':resourceId', resourceId);
}

export function createAdvisoryDetailsLink(advisoryId) {
  return Page.ADVISORY_DETAIL.replace(':id', advisoryId);
}

export function createAdvisoryTemplateDetailsLink(templateId) {
  return Page.ADVISORY_TEMPLATE_DETAIL.replace(':id', templateId);
}

export const createUpdateAdvisoryLink = (advisoryId) => {
  return Page.UPDATE_ADVISORY.replaceAll(':id', advisoryId);
};
export const createCreateAdvisoryFromTemplateLink = (templateId) => {
  return Page.CREATE_ADVISORY_FROM_TEMPLATE.replaceAll(':templateid', templateId);
};
export const createCreateAdvisoryFromAdvisoryLink = (advisoryId) => {
  return Page.CREATE_ADVISORY_FROM_ADVISORY.replaceAll(':id', advisoryId);
};
export const createSendAdvisoryNotificationLink = (advisoryId) => {
  return Page.ADVISORY_SEND_NOTIFICATION.replaceAll(':id', advisoryId);
};
export const createUpdateAdvisoryTemplateLink = (templateId) => {
  return Page.UPDATE_ADVISORY_TEMPLATE.replaceAll(':id', templateId);
};

export const createRequestDetailsLink = (requestId) => {
  return Page.REQUEST_DETAILS.replaceAll(':id', requestId);
};
export const createWSRequestDetailsLink = (requestId) => {
  return Page.WORKSPACE_MY_REQUEST_DETAILS.replaceAll(':id', requestId);
};
export const createWSPermissionLFLink = (permissionId) => {
  return Page.LF_PERMISSION_DETAIL.replaceAll(':permissionId', permissionId);
};
export const createGroupPermissionLFLink = (permissionId) => {
  return Page.DATASETSHARE_DETAILS.replaceAll(':id', permissionId);
};

export function createPosixGroupLink(posixGroup) {
  return `https://permissions.amazon.com/group.mhtml?group=${posixGroup}&group_type=posix`;
}

export function createMetricDefinitionLink(id) {
  return Page.METRICS_CATALOG + '/' + id;
}

export function createMetricCollectionDefinitionLink(id) {
  return Page.METRIC_COLLECTIONS_CATALOG + '/' + id;
}

export function createReportingTableDefinitionLink(id) {
  return Page.REPORTING_TABLE_CATALOG + '/' + id;
}

export function createMetricCollectionMonthlyLink(metricCollectionId) {
  return Page.METRIC_COLLECTION_MONTHLY_VIEW.replaceAll(':id', metricCollectionId);
}

export function createMetricCollectionWeeklyLink(metricCollectionId) {
  return Page.METRIC_COLLECTION_WEEKLY_VIEW.replaceAll(':id', metricCollectionId);
}

export function createMetricCollectionQuarterlyLink(metricCollectionId) {
  return Page.METRIC_COLLECTION_QUARTERLY_VIEW.replaceAll(':id', metricCollectionId);
}

export function createMetricCollectionYearlyLink(metricCollectionId) {
  return Page.METRIC_COLLECTION_YEARLY_VIEW.replaceAll(':id', metricCollectionId);
}

export function createWorkflowStatusLink(id) {
  return Page.WORKFLOW_STATUS.replace(':id', id);
}
// determine the type of resource in the ARN and redirect to one of the above helpers
export function createLinkForResourceArn(arn: string) {
  const idInfo = getIdInfoFromArn(arn);
  const id = generateIdFromArn(arn);
  const scope = getScopeForArn(arn);
  if (scope == Scope.Catalog) {
    if (idInfo?.dataSource == 'redshift') {
      return createRedshiftCatalogDetailLink(idInfo.catalogId, idInfo.clusterId, idInfo.region);
    } else {
      return createGlueCatalogDetailLink(idInfo.catalogId, idInfo.region);
    }
  } else if (scope == Scope.Database) {
    if (idInfo.dataSource == 'redshift') {
      return createRedshiftDatabaseDetailLink(idInfo.catalogId, idInfo.clusterId, idInfo.databaseName, idInfo.region);
    } else {
      return createGlueDatabaseDetailLink(idInfo.catalogId, idInfo.databaseName, idInfo.region);
    }
  } else if (scope == Scope.Schema) {
    return createSchemaDetailLink(
      idInfo.catalogId,
      idInfo.clusterId,
      idInfo.databaseName,
      idInfo.schemaName,
      idInfo.region,
    );
  } else if (scope == Scope.Table) {
    return createDatasetDetailLink(id);
  } else if (scope == Scope.Column) {
    return '/';
  } else {
    return '/';
  }
}
