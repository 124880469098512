import * as React from 'react';
import { useState, useEffect } from 'react';

import { Redirect } from 'react-router-dom';
import { Alert, Button, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react-v3';
import { validatePrerequisites } from 'src/api/permissions';
import {
  DataZoneMembershipDesignationInfoList,
  ValidationDetailList,
} from 'aws-sdk/clients/awsdatalakegladstonelambda';

export interface ValidatePrerequisitesContentProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  match: any;
  workspaceId: string;
  awsAccountId: string;
  region: string;
  clusterId: string;
  databaseName: string;
  secretArn: string;
  workgroupName: string;
  setPrerequisitesPassed: any;
  membershipList: DataZoneMembershipDesignationInfoList;
  errorMessage: string;
}

export const ValidatePrerequisitesContent = (props: ValidatePrerequisitesContentProps) => {
  const [redirect, setRedirect] = useState<string>(undefined);
  const [result, setResult] = useState<ValidationDetailList>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const resourceTypeMapping = {
    WorkspaceRolePermission: 'Workspace role validation',
    RedshiftClusterValidation: 'Redshift cluster validation',
    RedshiftServerlessValidation: 'Redshift serverless validation',
    MembershipDesignationValidation: 'Project membership validation',
  };

  const statusToIndicator = (isValid: boolean) => {
    return <StatusIndicator type={isValid ? 'success' : 'error'}>{isValid ? 'Success' : 'Error'}</StatusIndicator>;
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = async () => {};

  const startPrerequisitesCheck = async () => {
    setLoading(true);
    // TODO: catch exceptions
    const validatePrerequisitesResult = await validatePrerequisites({
      dataZonePrerequisites: {
        workspaceRoleInfo: {
          awsAccountId: props.awsAccountId,
          region: props.region,
          awsAccountClassificationExpected: ['NonProduction'],
        },
        membershipList: props.membershipList,
        redshiftInfo: {
          awsAccountId: props.awsAccountId,
          region: props.region,
          clusterName: props.clusterId,
          databaseName: props.databaseName,
          secretArn: props.secretArn,
          workgroupName: props.workgroupName,
        },
      },
      ownerId: props.workspaceId,
    });
    setResult(validatePrerequisitesResult.dataZonePrerequisitesResult.dataZonePrerequisitesValidations);
    // if all validations passed, we can proceed
    if (
      !validatePrerequisitesResult.dataZonePrerequisitesResult.dataZonePrerequisitesValidations
        .map((validation) => validation.isValid)
        .includes(false)
    ) {
      props.setPrerequisitesPassed(true);
    }
    setLoading(false);
  };

  if (redirect) {
    if (redirect) return <Redirect push to={redirect} />;
  }

  return (
    <>
      {props.errorMessage && <Alert type={'error'}>{props.errorMessage}</Alert>}
      <Button onClick={startPrerequisitesCheck}>Start prerequisites check</Button>
      {loading && (
        <>
          <br />
          <StatusIndicator type='loading'>Loading results</StatusIndicator>
        </>
      )}
      {result &&
        result.map((resultItem) => (
          <>
            <p>
              <h3>{resourceTypeMapping[resultItem.validatedResource]}</h3>
              {statusToIndicator(resultItem.isValid)}
              {resultItem.isValid == false && (
                <>
                  <br />
                  {resultItem.cause}
                </>
              )}
            </p>
            <br />
          </>
        ))}
    </>
  );
  // TODO: the UI should have links into the DZ portal for common tasks
  // Create data source, browse catalog, view inventory, etc
  // TODO: a "review section", like "the following will be created:"
};
