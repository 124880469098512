import { MidwayNetworkPartition } from '@amzn/midway-identity-credential-provider';

export const PUBLISHER_CLIENT_CONFIG = {
  beta: {
    endpoint: 'https://twcx7nfaw1.execute-api.us-east-1.amazonaws.com/beta',
    region: 'us-east-1',
  },
  gamma_iad: {
    endpoint: 'https://7u56lu6vr8.execute-api.us-east-1.amazonaws.com/gamma',
    region: 'us-east-1',
  },
  gamma_pdx: {
    endpoint: 'https://jvrgx6g96d.execute-api.us-west-2.amazonaws.com/gamma',
    region: 'us-west-2',
  },
  prod_iad: {
    endpoint: 'https://zc955e0fp2.execute-api.us-east-1.amazonaws.com/prod',
    region: 'us-east-1',
  },
  prod_pdx: {
    endpoint: 'https://9sv7rqi9d1.execute-api.us-west-2.amazonaws.com/prod',
    region: 'us-west-2',
  },
  preprod_zhy: {
    endpoint: 'https://78moxsk02f.execute-api.cn-northwest-1.amazonaws.com.cn/preprodspecial',
    region: 'cn-northwest-1',
  },
  prod_zhy: {
    endpoint: 'https://zeyapnojza.execute-api.cn-northwest-1.amazonaws.com.cn/prodspecial',
    region: 'cn-northwest-1',
  },
  gamma_lck: {
    endpoint: '',
    region: 'us-isob-east-1',
  },
  gamma_dca: {
    endpoint: '',
    region: 'us-iso-east-1',
  },
  prod_lck: {
    endpoint: '',
    region: 'us-isob-east-1',
  },
  prod_dca: {
    endpoint: '',
    region: 'us-iso-east-1',
  },
  gamma_ale: {
    endpoint: '',
    region: 'us-isof-south-1',
  },
  gamma_ncl: {
    endpoint: '',
    region: 'eu-isoe-west-1',
  },
  prod_ale: {
    endpoint: '',
    region: 'us-isof-south-1',
  },
  prod_ncl: {
    endpoint: '',
    region: 'eu-isoe-west-1',
  },
  gamma_tlv: {
    endpoint: '',
    region: 'il-central-1',
  },
  prod_tlv: {
    endpoint: '',
    region: 'il-central-1',
  },
  gamma_cmh: {
    endpoint: '',
    region: 'us-east-2',
  },
  prod_cmh: {
    endpoint: '',
    region: 'us-east-2',
  },
  local: {
    endpoint: 'https://twcx7nfaw1.execute-api.us-east-1.amazonaws.com/beta',
    region: 'us-east-1',
  },
};

export const DEPENDENCY_CLIENT_CONFIG = {
  beta: {
    endpoint: 'https://api.us-east-1.beta.dependencyservice.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
  gamma_iad: {
    endpoint: 'api.us-east-1.gamma.dependencyservice.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
  gamma_pdx: {
    endpoint: 'https://z16sl6p6u2.execute-api.us-west-2.amazonaws.com/gamma',
    region: 'us-west-2',
  },
  prod_iad: {
    endpoint: 'https://api.us-east-1.prod.dependencyservice.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
  prod_pdx: {
    endpoint: 'api.us-west-2.prod.dependencyservice.datalake.aws.a2z.com',
    region: 'us-west-2',
  },
  preprod_zhy: {
    endpoint: 'https://tjjmzgob46.execute-api.cn-northwest-1.amazonaws.com.cn/preprod-special',
    region: 'cn-northwest-1',
  },
  prod_zhy: {
    endpoint: 'https://v44i8rya6b.execute-api.cn-northwest-1.amazonaws.com.cn/prod-special',
    region: 'cn-northwest-1',
  },
  gamma_lck: {
    endpoint: '',
    region: 'us-isob-east-1',
  },
  gamma_dca: {
    endpoint: '',
    region: 'us-iso-east-1',
  },
  prod_lck: {
    endpoint: '',
    region: 'us-isob-east-1',
  },
  prod_dca: {
    endpoint: '',
    region: 'us-iso-east-1',
  },
  gamma_ale: {
    endpoint: '',
    region: 'us-isof-south-1',
  },
  gamma_ncl: {
    endpoint: '',
    region: 'eu-isoe-west-1',
  },
  prod_ale: {
    endpoint: '',
    region: 'us-isof-south-1',
  },
  prod_ncl: {
    endpoint: '',
    region: 'eu-isoe-west-1',
  },
  gamma_tlv: {
    endpoint: '',
    region: 'il-central-1',
  },
  prod_tlv: {
    endpoint: '',
    region: 'il-central-1',
  },
  gamma_cmh: {
    endpoint: '',
    region: 'us-east-2',
  },
  prod_cmh: {
    endpoint: '',
    region: 'us-east-2',
  },
  local: {
    endpoint: 'https://api.us-east-1.beta.dependencyservice.datalake.aws.a2z.com',
    region: 'us-east-1',
  },
};

// ID of the Cognito identity pool, only used for original stages (beta IAD, prod IAD)
export const AUTH_CONFIG = {
  beta: {
    identityPoolId: 'us-east-1:a520fe3c-e368-46ef-aef4-c653ac004ef5',
    region: 'us-east-1',
  },
  prod_iad: {
    identityPoolId: 'us-east-1:34149c48-2cd6-4924-8d85-ea46f31d8f35',
    region: 'us-east-1',
  },
  local: {
    identityPoolId: 'us-east-1:a520fe3c-e368-46ef-aef4-c653ac004ef5',
    region: 'us-east-1',
  },
};

export const RIP_STAGE_MAPPING = {
  beta: 'beta',
  gamma_iad: 'gamma',
  gamma_pdx: 'gamma',
  prod_iad: 'prod',
  prod_pdx: 'prod',
  preprod_zhy: 'preprod-special',
  prod_zhy: 'prod-special',
  gamma_lck: 'gamma-prime',
  gamma_dca: 'gamma-prime',
  prod_lck: 'prod-prime',
  prod_dca: 'prod-prime',
  gamma_ale: 'gamma-prime',
  gamma_ncl: 'gamma-prime',
  prod_ale: 'prod-prime',
  prod_ncl: 'prod-prime',
  gamma_tlv: 'gamma',
  prod_tlv: 'prod',
  gamma_cmh: 'gamma',
  prod_cmh: 'prod',
  local: 'beta',
  pdx_prime: 'pdx-prime',
};
export const RIP_STAGE_MAPPING_MSI_TETHYS = {
  //Temp changes to have different stage mapping for ALE and NCL, We should update the RIP service engine package later.
  beta: 'beta',
  gamma_iad: 'gamma',
  gamma_pdx: 'gamma',
  prod_iad: 'prod',
  prod_pdx: 'prod',
  preprod_zhy: 'preprod-special',
  prod_zhy: 'prod-special',
  gamma_lck: 'gamma-prime',
  gamma_dca: 'gamma-prime',
  prod_lck: 'prod-prime',
  prod_dca: 'prod-prime',
  gamma_ale: 'gamma',
  gamma_ncl: 'gamma',
  prod_ale: 'prod',
  prod_ncl: 'prod',
  gamma_tlv: 'gamma',
  prod_tlv: 'prod',
  gamma_cmh: 'gamma',
  prod_cmh: 'prod',
  local: 'beta',
  pdx_prime: 'pdx-prime',
};

export const ACCESS_LOG_COLLECTOR_CONFIG = {
  beta: {
    endpoint: 'https://9dc2d08iyh.execute-api.us-east-1.amazonaws.com/beta',
    region: 'us-east-1',
  },
  gamma_iad: {
    endpoint: 'https://fh76szuzh0.execute-api.us-east-1.amazonaws.com/gamma',
    region: 'us-east-1',
  },
  gamma_pdx: {
    endpoint: 'https://nw7gy8eoh3.execute-api.us-west-2.amazonaws.com/gamma',
    region: 'us-west-2',
  },
  prod_iad: {
    endpoint: 'https://rrh6rvuh4k.execute-api.us-east-1.amazonaws.com/prod',
    region: 'us-east-1',
  },
  prod_pdx: {
    endpoint: 'https://qcsnsmm7pd.execute-api.us-west-2.amazonaws.com/prod',
    region: 'us-west-2',
  },
  preprod_zhy: {
    endpoint: 'https://op8yqqzdt4.execute-api.cn-northwest-1.amazonaws.com.cn/gamma',
    region: 'cn-northwest-1',
  },
  prod_zhy: {
    endpoint: 'https://snwlb8lqsf.execute-api.cn-northwest-1.amazonaws.com.cn/prod',
    region: 'cn-northwest-1',
  },
  gamma_lck: {
    endpoint: '',
    region: 'us-isob-east-1',
  },
  gamma_dca: {
    endpoint: '',
    region: 'us-iso-east-1',
  },
  prod_lck: {
    endpoint: '',
    region: 'us-isob-east-1',
  },
  prod_dca: {
    endpoint: '',
    region: 'us-iso-east-1',
  },
  gamma_ale: {
    endpoint: '',
    region: 'us-isof-south-1',
  },
  gamma_ncl: {
    endpoint: '',
    region: 'eu-isoe-west-1',
  },
  prod_ale: {
    endpoint: '',
    region: 'us-isof-south-1',
  },
  prod_ncl: {
    endpoint: '',
    region: 'eu-isoe-west-1',
  },
  gamma_tlv: {
    endpoint: '',
    region: 'il-central-1',
  },
  prod_tlv: {
    endpoint: '',
    region: 'il-central-1',
  },
  gamma_cmh: {
    endpoint: '',
    region: 'us-east-2',
  },
  prod_cmh: {
    endpoint: '',
    region: 'us-east-2',
  },
  local: {
    endpoint: 'https://9dc2d08iyh.execute-api.us-east-1.amazonaws.com/beta',
    region: 'us-east-1',
  },
};

// We should replace this in the future with something that can scale
export const DATA_ACCESS_CONFIG = {
  beta: {
    accountId: '773135466957',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: 'arn:aws:iam::773135466957:role/GlueAndLakeFormationAdmin',
    ramRoleArn: 'arn:aws:iam::773135466957:role/RamAdmin',
    region: 'us-east-1',
  },
  gamma_iad: {
    accountId: '773135466957',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: 'arn:aws:iam::773135466957:role/GlueAndLakeFormationAdmin',
    ramRoleArn: 'arn:aws:iam::773135466957:role/RamAdmin',
    region: 'us-east-1',
  },
  gamma_pdx: {
    accountId: '673735269386',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: 'arn:aws:iam::673735269386:role/GlueAndLakeFormationAdmin',
    ramRoleArn: 'arn:aws:iam::673735269386:role/RamAdmin',
    region: 'us-west-2',
  },
  prod_iad: {
    accountId: '316343021201',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: 'arn:aws:iam::316343021201:role/GlueAndLakeFormationAdmin',
    ramRoleArn: 'arn:aws:iam::316343021201:role/RamAdmin',
    region: 'us-east-1',
  },
  prod_pdx: {
    accountId: '673735269386',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: 'arn:aws:iam::673735269386:role/GlueAndLakeFormationAdmin',
    ramRoleArn: 'arn:aws:iam::673735269386:role/RamAdmin',
    region: 'us-west-2',
  },
  preprod_zhy: {
    accountId: '277664194035',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: 'arn:aws-cn:iam::277664194035:role/GlueAndLakeFormationAdmin',
    ramRoleArn: 'arn:aws-cn:iam::277664194035:role/RamAdmin',
    region: 'cn-northwest-1',
  },
  prod_zhy: {
    accountId: '277664194035',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: 'arn:aws-cn:iam::277664194035:role/GlueAndLakeFormationAdmin',
    ramRoleArn: 'arn:aws-cn:iam::277664194035:role/RamAdmin',
    region: 'cn-northwest-1',
  },
  gamma_lck: {
    accountId: '',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: '',
    ramRoleArn: '',
    region: 'us-isob-east-1',
  },
  gamma_dca: {
    accountId: '',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: '',
    ramRoleArn: '',
    region: 'us-iso-east-1',
  },
  prod_lck: {
    accountId: '',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: '',
    ramRoleArn: '',
    region: 'us-isob-east-1',
  },
  prod_dca: {
    accountId: '',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: '',
    ramRoleArn: '',
    region: 'us-iso-east-1',
  },
  gamma_ale: {
    accountId: '',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: '',
    ramRoleArn: '',
    region: 'us-isof-south-1',
  },
  gamma_ncl: {
    accountId: '',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: '',
    ramRoleArn: '',
    region: 'eu-isoe-west-1',
  },
  prod_ale: {
    accountId: '',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: '',
    ramRoleArn: '',
    region: 'us-isof-south-1',
  },
  prod_ncl: {
    accountId: '',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: '',
    ramRoleArn: '',
    region: 'eu-isoe-west-1',
  },
  gamma_tlv: {
    accountId: '',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: '',
    ramRoleArn: '',
    region: 'il-central-1',
  },
  prod_tlv: {
    accountId: '',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: '',
    ramRoleArn: '',
    region: 'il-central-1',
  },
  gamma_cmh: {
    accountId: '',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: '',
    ramRoleArn: '',
    region: 'us-east-2',
  },
  prod_cmh: {
    accountId: '',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: '',
    ramRoleArn: '',
    region: 'us-east-2',
  },
  local: {
    accountId: '773135466957',
    groupId: 'AWSDL_HybridCatalog',
    dataAccessRoleArn: 'arn:aws:iam::773135466957:role/GlueAndLakeFormationAdmin',
    ramRoleArn: 'arn:aws:iam::773135466957:role/RamAdmin',
    region: 'us-east-1',
  },
};

export const GALAXI_CONFIG = {
  beta: {
    galaxiURL: 'https://beta.galaxi.aws.dev/',
  },
  gamma_iad: {
    galaxiURL: 'https://gamma.galaxi.aws.dev/',
  },
  prod_iad: {
    galaxiURL: 'https://galaxi.aws.dev/',
  },
  local: {
    galaxiURL: 'https://alpha.galaxi.aws.dev/',
  },
};

export const TETHYS_ACCOUNT_ID = {
  beta: '997545651165',
  gamma_iad: '',
  gamma_pdx: '',
  prod_iad: '483944478070',
  prod_pdx: '',
  preprod_zhy: '307535402649',
  prod_zhy: '362796149014',
  gamma_lck: '683506551074',
  gamma_dca: '316986650414',
  gamma_ale: '476411689981',
  gamma_ncl: '325170565952',
  prod_ale: '144423165766',
  prod_ncl: '813840700359',
  prod_lck: '478297226519',
  prod_dca: '386419579334',
  gamma_tlv: '415764777069',
  prod_tlv: '',
  gamma_cmh: '639313563529',
  prod_cmh: '',
  local: '997545651165',
};

export const SUBSCRIPTIONS_ACCOUNT_ID = {
  beta: '447294440808',
  gamma_iad: '857361947973',
  gamma_pdx: '173742831925',
  prod_iad: '820710398701',
  prod_pdx: '260728829752',
  preprod_zhy: '486533350692',
  prod_zhy: '486538533819',
  gamma_lck: '595093674711',
  gamma_dca: '183889542342',
  prod_lck: '694842207035',
  prod_dca: '048384812958',
  gamma_ale: '420344809709',
  gamma_ncl: '092850528735',
  prod_ale: '470370210420',
  prod_ncl: '376342377322',
  gamma_tlv: '939056182309',
  prod_tlv: '750823906093',
  gamma_cmh: '273170256571',
  prod_cmh: '136311696736',
  local: '447294440808',
  pdx_prime: '743157663159',
};

export const AMAZON_APPROVALS_CONFIG = {
  beta: 'https://approvalstage.amazon.com/',
  gamma_iad: 'https://approvalstage.amazon.com/',
  gamma_pdx: 'https://approvalstage.amazon.com/',
  // "approval.amazon.com" and "approvals.amazon.com" are interchangeable
  prod_iad: 'https://approval.amazon.com/',
  prod_pdx: 'https://approval.amazon.com/',
  preprod_zhy: '',
  prod_zhy: '',
  gamma_lck: '',
  gamma_dca: '',
  gamma_ale: '',
  gamma_ncl: '',
  prod_ale: '',
  prod_ncl: '',
  prod_lck: '',
  prod_dca: '',
  local: 'https://approvalstage.amazon.com/',
};

// TODO: Get the endpoints from RIP.
export const METRIC_STORE_STAGE_API_ENDPOINT_MAP: { [key: string]: string } = {
  ['local']: 'api.us-east-1.beta.metricstore.dataplatform.aws.dev',
  ['beta']: 'api.us-east-1.beta.metricstore.dataplatform.aws.dev',
  ['gamma_iad']: 'api.us-east-1.gamma.metricstore.dataplatform.aws.dev',
  ['prod_iad']: 'api.us-east-1.prod.metricstore.dataplatform.aws.dev',
  ['prod_pdx']: '',
  ['preprod_zhy']: '',
  ['prod_zhy']: '',
  ['gamma_lck']: '',
  ['gamma_dca']: '',
  ['gamma_ale']: '',
  ['gamma_ncl']: '',
  ['prod_ale']: '',
  ['prod_ncl']: '',
  ['prod_lck']: '',
  ['prod_dca']: '',
};

export function getMidwayAuthorizedRoleArn() {
  return 'arn:' + getPartition() + ':iam::' + getSubscriptionsAccount() + ':role/MidwayAuthorizeRole';
}

export function getPartition() {
  const region = getRegion();
  const partitionMap = {
    'cn-northwest-1': 'aws-cn',
    'us-iso-east-1': 'aws-iso',
    'us-isob-east-1': 'aws-iso-b',
    'us-isof-south-1': 'aws-iso-f',
    'eu-isoe-west-1': 'aws-iso-e',
  };

  return partitionMap[region] || 'aws';
}

export function getMidwayPartition(): MidwayNetworkPartition {
  const partition = getPartition();
  const partitionMap: Record<string, MidwayNetworkPartition> = {
    aws: MidwayNetworkPartition.AWS,
    'aws-cn': MidwayNetworkPartition.AWS_CN,
    'aws-iso': MidwayNetworkPartition.AWS_ISO,
    'aws-iso-b': MidwayNetworkPartition.AWS_ISO_B,
    'aws-iso-e': MidwayNetworkPartition.AWS_ISO_E,
    'aws-iso-f': MidwayNetworkPartition.AWS_ISO_F,
  };

  return partitionMap[partition] || MidwayNetworkPartition.AWS;
}

export function isIso() {
  return (
    getStage() == 'gamma_lck' ||
    getStage() == 'prod_lck' ||
    getStage() == 'prod_dca' ||
    getStage() == 'gamma_dca' ||
    getStage() == 'pdx_prime' ||
    getStage() == 'gamma_ale' ||
    getStage() == 'prod_ale' ||
    getStage() == 'gamma_ncl' ||
    getStage() == 'prod_ncl'
  );
}

export function getStage(): (typeof stageMap)[keyof typeof stageMap] | 'local' {
  const url = window.location.hostname;
  if (!url) {
    return 'local';
  }

  const stageMap = {
    'beta.': 'beta',
    'us-east-1.gamma.': 'gamma_iad',
    'us-west-2.gamma.': 'gamma_pdx',
    'us-east-1.omni.': 'prod_iad',
    'us-west-2.omni.': 'prod_pdx',
    'cn-northwest-1.preprod.': 'preprod_zhy',
    'cn-northwest-1.omni.': 'prod_zhy',
    'us-isob-east-1.gamma-prime.': 'gamma_lck',
    'us-iso-east-1.gamma-prime.': 'gamma_dca',
    'us-isob-east-1.prod-prime.': 'prod_lck',
    'us-iso-east-1.prod-prime.': 'prod_dca',
    'us-isof-south-1.gamma-prime.': 'gamma_ale',
    'eu-isoe-west-1.gamma-prime.': 'gamma_ncl',
    'us-isof-south-1.prod-prime.': 'prod_ale',
    'eu-isoe-west-1.prod-prime.': 'prod_ncl',
    'il-central-1.gamma.': 'gamma_tlv',
    'il-central-1.omni.': 'prod_tlv',
    'us-east-2.gamma.': 'gamma_cmh',
    'us-east-2.omni.': 'prod_cmh',
    'us-west-2.pdx-prime': 'pdx_prime',
    // this case must go second-to-last after checking all other domains, this handles the case of
    // the default omni.datalake.aws.a2z.com domain
    'omni.': 'prod_iad',
  } as const;

  for (const [key, value] of Object.entries(stageMap)) {
    if (url.includes(key)) {
      return value;
    }
  }

  return 'local';
}

export function authUsesCognito() {
  const stage = getStage();
  if (stage === 'prod_iad' || stage === 'beta' || stage === 'local') {
    return true;
  }
  return false;
}

export function authUsesIsengard() {
  const stage = getStage();
  const isengardStages: (typeof stage)[] = ['prod_ale', 'gamma_ale', 'prod_ncl', 'gamma_ncl'];
  return isengardStages.includes(stage);
}

export function getTethysAccount() {
  return TETHYS_ACCOUNT_ID[getStage()];
}

export function getSubscriptionsAccount() {
  return SUBSCRIPTIONS_ACCOUNT_ID[getStage()];
}

export function isProd() {
  const stage = getStage();
  return stage.includes('prod');
}

export function getRipStage() {
  return RIP_STAGE_MAPPING[getStage()];
}

export function getRipStageForMSIAndTethys() {
  return RIP_STAGE_MAPPING_MSI_TETHYS[getStage()];
}

export function getSubscriptionConfig() {
  return {
    endpoint: JSON.parse(process.env['SUBSCRIPTIONS_ENDPOINT_MAP'])[getRegion()][getRipStage()],
    region: getRegion(),
  };
}

export function getCatalogConfig() {
  if (getRipStage() === 'pdx-prime') {
    return {
      endpoint: 'api.us-west-2.pdx-prime.hybridcatalog.datalake.aws.a2z.com',
      region: getRegion(),
    };
  }
  return {
    //endpoint: JSON.parse(process.env['HC_ENDPOINT_MAP'])[getRegion()][getRipStage()],
    //endpoint: 'https://074yapmho0.execute-api.us-east-1.amazonaws.com/beta',
    endpoint: 'https://p0iqxkiorl.execute-api.us-east-1.amazonaws.com/beta',
    region: getRegion(),
  };
}

export function getGladstoneConfig() {
  return {
    //endpoint: JSON.parse(process.env['GLADSTONE_ENDPOINT_MAP'])[getRegion()][getRipStage()],
    //endpoint: 'https://gxdke7yq97.execute-api.us-east-1.amazonaws.com/test',
    endpoint: 'https://jv32fv9i8a.execute-api.us-east-1.amazonaws.com/beta',
    region: getRegion(),
  };
}

export function isLakeFormationAvailable() {
  return JSON.parse(process.env['LAKE_FORMATION_AVAILABLE_MAP'])[getRegion()] == 'true';
}
export function enableIamBasedAccess() {
  // support IAM-based access in regions where LF is not available, or in test regions like TLV.
  return !isLakeFormationAvailable() || getRegion() == 'il-central-1' || isIso();
}

export function enableAdvisories() {
  // Advisories only in beta/gamma/prod IAD. This can be expanded later if we want.
  return getRegion() == 'us-east-1';
}

export function getDependencyConfig() {
  return DEPENDENCY_CLIENT_CONFIG[getStage()];
}

export function getPublisherConfig() {
  return PUBLISHER_CLIENT_CONFIG[getStage()];
}

export function getNotificationConfig() {
  if (getRipStage() === 'pdx-prime') {
    return {
      endpoint: 'api.us-west-2.pdx-prime.omniapi.datalake.aws.a2z.com',
      region: getRegion(),
    };
  }
  return {
    endpoint: JSON.parse(process.env['OMNI_API_ENDPOINT_MAP'])[getRegion()][getRipStage()],
    region: getRegion(),
  };
}
export function getAccessLogCollectorConfig() {
  return ACCESS_LOG_COLLECTOR_CONFIG[getStage()];
}

export function getGalaxiCatalogEndpointURL() {
  return GALAXI_CONFIG[getStage()]?.galaxiURL;
}

export function galaxiServiceIntegrated() {
  return GALAXI_CONFIG[getStage()] != null;
}

export function getResourcesManagerConfig() {
  //This will take the stage from getRipStageForMSIAndTethys and will get the endpoint based on Region and stage.
  return {
    endpoint: JSON.parse(process.env['RM_ENDPOINT_MAP'])[getRegion()][getRipStageForMSIAndTethys()],
    region: getRegion(),
  };
}

//Tethys data service endpoint
export function getDatasetServiceConfig() {
  //This will take the stage from getRipStageForMSIAndTethys and will get the endpoint based on Region and stage.
  //TODO: Tethys endpoints are recently updated to RIP for ALE and NCL, RIPStaticConfig is not updated yet. We are hardcoding
  // for now and will remove once updated.
  const region = getRegion();
  const ripStage = getRipStageForMSIAndTethys();
  const endpointMap = {
    'eu-isoe-west-1': {
      gamma: 'api.eu-isoe-west-1.gamma.tethys.datalake.a2z.adc-e.uk',
      prod: 'api.eu-isoe-west-1.prod.tethys.datalake.a2z.adc-e.uk',
    },
    'us-isof-south-1': {
      gamma: 'api.us-isof-south-1.gamma.tethys.datalake.a2z.hci.ic.gov',
      prod: 'api.us-isof-south-1.prod.tethys.datalake.a2z.hci.ic.gov',
    },
  };
  const endpoint = endpointMap[region]?.[ripStage];
  if (endpoint) {
    return { endpoint, region };
  }
  return {
    endpoint: JSON.parse(process.env['TETHYS_ENDPOINT_MAP'])[region][ripStage],
    region,
  };
}

export function getAuthConfig() {
  // only used for the Cognito approach
  if (!authUsesCognito()) {
    return {};
  }
  return AUTH_CONFIG[getStage()];
}

export function getDataAccessConfig() {
  return DATA_ACCESS_CONFIG[getStage()];
}

export function getRegion() {
  // const url = window.location.hostname;
  // const stage = getStage();
  // const prefix = url.substr(0, url.indexOf('.'));
  // // there are only three cases where the region at the beginning is optional: development, beta, and prod IAD
  // if (stage === 'local' || prefix == 'beta' || prefix == 'omni') {
  //   return 'us-east-1';
  // } else {
  //   return prefix;
  // }
  return 'us-east-1';
}

export function getURL() {
  return 'https://' + window.location.hostname;
}

export function getAmazonApprovalURL() {
  return AMAZON_APPROVALS_CONFIG[getStage()];
}

export function isApprovalsServiceAvailable() {
  const region = getRegion();
  return region == 'us-east-1' || region == 'us-west-2';
}

export function getHCAdminGroup() {
  return `hybrid-catalog-admin-${getRipStage()}-${getRegion()}`;
}
